<template>
  <div class="assistant-trigger" @click="handlerBot">
    <img
      v-if="openBot"
      class="rounded-full"
      :src="useLoginet ? currentBot?.image : currentAssistant.avatar"
      :alt="useLoginet ? currentBot?.name : currentAssistant.name"/>
    <video
      v-else
      :poster="useLoginet ? currentBot?.image : currentAssistant.avatar"
      class="rounded-full"
      :src="useLoginet ? currentBot?.idleVideo : currentAssistant.idle_url"
      playsinline
      autoplay
      muted
      loop></video>
  </div>
  <Transition name="fade">
    <div v-if="openBot" class="assistant-viewport">
      <div class="flex flex-col bg-white h-full p-2 rounded-lg">
        <div class="flex justify-between items-center py-2">
          <BaseDropdown
            v-if="assistants.length > 1"
            placement="bottom"
            align="left"
            fixed
            :gap="0">
            <template #trigger="{ triggered }">
              <div class="flex justify-between gap-2">
                <div>{{ currentBot?.name }}</div>
                <ChevronDown :size="16" :class="{ 'rotate-180': triggered }" />
              </div>
            </template>
            <ListGroup>
              <div
                v-for="(bot, botIndex) in allBots"
                :key="bot.id"
                class="flex p-2 items-center"
                @click="changeAssistant(bot.id)">
                <div
                  class="flex flex-row items-center gap-2 w-full hover:bg-[#EAEEF6] cursor-pointer rounded-xl px-2.5 py-1"
                  :class="{
                    'border-b border-gray-200 bg-[#F7F9FD]': botIndex === 0,
                  }">
                  <BaseAvatar stacked :img="bot.image" rounded />
                  <div class="flex flex-col">
                    <span class="font-simplerBold text-xs">{{ bot.name }}</span>
                    <span class="text-xxs">{{ getLevel(bot.id) }}</span>
                  </div>
                </div>
              </div>
            </ListGroup>
          </BaseDropdown>
          <CloseIcon
            color="black"
            class="cursor-pointer hover:scale-110"
            :size="16"
            @click="handlerBot"/>
        </div>
        <div
          class="video-box relative rounded-lg h-44"
          :class="currentAssistant.cover"
          :style="`background-color: ${currentAssistant.backgroundColor}`">
          <div class="absolute w-2/3 h-full">
            <div
              v-show="!showVideo"
              class="w-full h-full flex items-center justify-center z-2">
              <video
                :poster="
                  useLoginet ? currentBot?.image : currentAssistant.avatar
                "
                class="rounded-full w-25 h-25"
                :src="
                  useLoginet ? currentBot?.idleVideo : currentAssistant.idle_url
                "
                playsinline
                autoplay
                muted
                loop></video>
            </div>
            <video
              v-show="idle && responseType === 'video'"
              ref="idleVideo"
              class="aspect-square w-full h-full absolute object-cover blur-edges z-2"
              :src="
                useLoginet ? currentBot?.idleVideo : currentAssistant.idle_url
              "
              playsinline
              autoplay
              muted
              loop
              @canplay="ready = true"></video>
            <video
              v-show="(useLoginet || videoURL) && responseType === 'video'"
              ref="talkVideo"
              :src="videoURL"
              :muted="mutedVideo"
              :playbackRate="videoSpeed"
              class="aspect-square w-full absolute blur-edges h-full object-cover z-1"
              playsinline
              autoplay
              @canplay="ready = true"></video>
            <video
              v-show="useLoginet && responseType === 'video'"
              ref="talkVideo2"
              :muted="mutedVideo"
              class="aspect-square w-full absolute blur-edges h-full object-cover z-1"
              :playbackRate="videoSpeed"
              playsinline
              autoplay
              @canplay="ready = true"></video>
            <loading-overlay
              :active="!ready"
              :can-cancel="false"
              :is-full-page="false"
              :opacity="0.5"
              :z-index="9999999"
              color="#fff"
              background-color="#333"/>
          </div>
          <div class="absolute settings flex items-center gap-2 z-[99999]">
            <button class="speed-btn" @click="onVideoSpeedClick()">
              x {{ videoSpeed }}
            </button>
            <button v-if="useLoginet" @click="showVideo = !showVideo">
              <VideoIcon v-if="showVideo" class="stroke-white w-4 h-4" />
              <VideoOff v-else class="stroke-white w-4 h-4" />
            </button>
            <button v-if="useLoginet" @click="audioOn = !audioOn">
              <Volume2 v-if="audioOn" class="stroke-white w-4 h-4" />
              <VolumeX v-else class="stroke-white w-4 h-4" />
            </button>
          </div>
        </div>
        <div
          ref="chatBox"
          class="flex flex-col flex-1 h-full overflow-y-auto overflow-x-hidden">
          <div class="flex flex-col gap-5 p-4 h-fit">
            <TransitionGroup name="list">
              <template v-for="message in computedMessages" :key="message.id">
                <div
                  class="flex flex-row items-center"
                  :class="{
                    'flex-row-reverse': !message.isMe,
                    'mb-3': !message.isMe && message.timeAgo,
                  }">
                  <div
                    dir="auto"
                    class="relative text-sm p-3"
                    :class="{
                      'bg-sky-blue-200 ml-5 rounded-l-lg rounded-tr-lg':
                        message.isMe,
                      'bg-primary mr-5 rounded-r-lg rounded-tl-lg text-white':
                        !message.isMe,
                    }">
                    <span v-if="message.content">
                      {{ removeHtml(message.content) }}
                    </span>
                    <TypingDots v-else class="fill-white" />
                    <span
                      v-if="!message.isMe && message.timeAgo"
                      class="absolute text-xs bottom-0 right-0 -mb-5 mr-2 text-gray-500 whitespace-nowrap">
                      {{ message.timeAgo }}
                    </span>
                  </div>
                </div>
              </template>
            </TransitionGroup>
          </div>
        </div>
        <div class="flex flex-row items-center h-16 border-t w-full px-4 gap-2">
          <div class="flex-grow">
            <div class="relative w-full">
              <input
                v-model="conversation"
                type="text"
                class="text-input"
                :placeholder="
                  currentAssistant.recognition_lang === 'en-US'
                    ? 'Type message...'
                    : 'הקלידו משהו'
                "
                @keyup.enter="sendMessage"/>
            </div>
          </div>
          <button class="voice-input">
            <MicIcon v-if="!isListening" color="white" @click="start" />
            <StopIcon v-else color="white" class="animate-ping" @click="stop" />
          </button>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script setup lang="ts">
import { computed, onMounted, onUnmounted, nextTick, ref, watch } from 'vue';
import { useSpeechRecognition } from '@vueuse/core';
import { he } from 'date-fns/locale';

import {
  Disc2 as StopIcon,
  Mic as MicIcon,
  X as CloseIcon,
  ChevronDown,
  Video as VideoIcon,
  VideoOff,
  Volume2,
  VolumeX,
} from 'lucide-vue-next';
import TypingDots from './typing.svg?component';
import { useConversation } from './useConveration';
import { useTimeAgo } from './useTimeAgo';
import { useLoginetConversation } from './useLoginetConversation';
import BaseDropdown from '@/components/Dropdown/BaseDropdown.vue';
import ListGroup from '@/components/ListGroup/ListGroup.vue';
import BaseAvatar from '@/components/Avatar/BaseAvatar.vue';
import LoadingOverlay from 'vue-loading-overlay';

const AVAILABLE_VIDEO_SPEED = [0.5, 1, 1.5];
type IBot = {
  cover?: string;
  avatar?: string;
  name?: string;
  label?: string;
  voice?: string;
  humain?: string;
  recognition_lang: string;
  idle_url?: string;
  botId?: string;
  backgroundColor?: string;
  image?: string;
  id?: string;
  level?: string;
};

type IAssistant = {
  type: string;
  settings: IBot;
};

type IMessage = {
  id: number;
  content?: string;
  isMe?: boolean;
  timeAgo?: any;
  botId?: string;
};

const props = defineProps<{ assistants: IAssistant[] }>();
const currentAssistant = ref(props.assistants[0].settings);
const timeAgo = useTimeAgo({ autoUpdate: true, locale: he });
const lastMessageId = computed(() => messages.value?.length - 1);
const ready = ref(false);

const updateLastMessage = ({ content }) => {
  if (content) {
    messages.value[lastMessageId.value] = {
      id: lastMessageId.value,
      content: content,
      timeAgo: timeAgo(new Date()),
    };
  }
};

const computedMessages = computed(() => {
  if (!useLoginet.value) {
    return messages.value;
  }
  return messages.value
    .map(message => {
      if (!message.botId) {
        message.botId = currentAssistant.value.botId;
      }
      return message;
    })
    .filter(message => message.botId === currentAssistant.value.botId);
});

const videoURL = ref<string | undefined>(undefined);

const useLoginet = computed(() => !!currentAssistant.value.botId);
const idleVideo = ref<HTMLVideoElement | null>(null);
const showVideo = ref(true);
const audioOn = ref(true);
const talkVideo = ref<HTMLVideoElement | null | any>(null);
const talkVideo2 = ref<HTMLVideoElement | null>(null);
const chatBox = ref<HTMLElement | null>(null);
const videoSpeedCtrlIndex = ref(1);
const openBot = ref(false);

const videoSpeed = computed(
  () => AVAILABLE_VIDEO_SPEED[videoSpeedCtrlIndex.value],
);

const mutedVideo = ref(false);

const handlerBot = () => {
  openBot.value = !openBot.value;
  if (openBot.value) {
    if (audioElement.value && audioOn.value) audioElement.value.muted = false;
  }
};

const responseType = computed(() => {
  if (showVideo.value) {
    return 'video';
  }
  return audioOn.value ? 'voice' : 'text';
});

watch(
  () => [responseType.value, audioOn.value],
  () => {
    if (responseType.value === 'text') {
      if (audioElement.value) audioElement.value.muted = true;
      mutedVideo.value = true;
      showVideo.value = false;
    }
    if (responseType.value === 'voice' && audioOn.value) {
      if (audioElement.value) audioElement.value.muted = false;
      showVideo.value = false;
      mutedVideo.value = false;
    }
    if (responseType.value === 'video' && audioOn.value) {
      if (audioElement.value) audioElement.value.muted = false;
      mutedVideo.value = false;
      showVideo.value = true;
    }
    if (responseType.value === 'video' && !audioOn.value) {
      if (audioElement.value) audioElement.value.muted = true;
      mutedVideo.value = true;
      showVideo.value = true;
    }
  },
);

const {
  ask: askLoginet,
  currentBot,
  connectToBot,
  fetchBots,
  disconnectFromBot,
  cleanChunksVideo,
  audioElement,
} = useLoginetConversation(
  updateLastMessage,
  talkVideo,
  talkVideo2,
  videoSpeed,
  responseType,
  openBot,
);
const { ask, say } = useConversation();

const onVideoSpeedClick = () => {
  videoSpeedCtrlIndex.value =
    (videoSpeedCtrlIndex.value + 1) % AVAILABLE_VIDEO_SPEED.length;
};

const idle = ref(true);
const messages = ref<IMessage[]>([]);
const conversation = ref('');
const allBots = ref<IBot[]>([]);

const getLevel = botId => {
  const currentAssistant = props.assistants.find(
    assistant => assistant.settings.botId === botId,
  );
  return currentAssistant ? currentAssistant.settings?.level : null;
};

const initializeBot = async () => {
  const storedBotId = localStorage.getItem('SELECTED_BOT_KEY');
  const savedAssistant = props.assistants.find(
    assistant => assistant.settings.botId === storedBotId,
  );

  allBots.value = await fetchAllBots();
  if (savedAssistant) {
    currentAssistant.value = savedAssistant.settings;
    await connectToBot(storedBotId);
    orderBots(storedBotId);
  } else {
    await connectToBot(allBots.value[0].id);
  }
};

onMounted(async () => {
  if (useLoginet.value) {
    await initializeBot();
  }
});

const fetchAllBots = async () => {
  const botIds = props.assistants.map(assistant => assistant.settings.botId);
  return await fetchBots(botIds);
};

const scrollDown = () => {
  chatBox.value?.scrollTo({
    top: chatBox.value?.scrollHeight || 0,
    behavior: 'smooth',
  });
};

watch(openBot, visible => {
  if (visible) {
    nextTick(() => {
      talkVideo.value?.addEventListener('ended', () => playIdleVideo());
      playIdleVideo();
      scrollDown();
    });
  } else {
    talkVideo.value?.removeEventListener('ended', () => playIdleVideo());
  }
});

const { isListening, start, stop, result, isFinal } = useSpeechRecognition({
  lang: currentAssistant.value.recognition_lang,
  interimResults: false,
  continuous: false,
});

watch(result, (text: any) => {
  if (result.value && isFinal.value) {
    conversation.value = text;
    sendMessage();
    result.value = '';
  }
});

watch(
  () => [messages.value.length, messages.value[lastMessageId.value]?.content],
  () => {
    nextTick(() => scrollDown());
  },
);

watch(videoURL, url => {
  setTimeout(() => {
    idle.value = !url;
  }, 200);
});

const playIdleVideo = () => {
  idle.value = true;
  setTimeout(() => {
    videoURL.value = undefined;
  }, 200);
};

const sendMessage = async () => {
  if (!conversation.value.trim()) {
    return;
  }
  messages.value.push({
    id: messages.value.length,
    content: conversation.value,
    isMe: true,
    timeAgo: timeAgo(new Date()),
  });

  let body = conversation.value;
  let messageId = messages.value.length;
  conversation.value = '';

  setTimeout(() => {
    playIdleVideo();
    messages.value.push({
      id: messageId,
    });
  }, 700);

  if (useLoginet.value) {
    askLoginet({ body });
  } else {
    const text = await ask({
      body,
      humain: currentAssistant.value.humain,
    });

    videoURL.value = await say({
      text,
      idle_url: currentAssistant.value.idle_url,
      azure_voice: currentAssistant.value.voice,
    });

    talkVideo.value.playbackRate = videoSpeed.value;

    messages.value[messageId] = {
      id: messageId,
      content: text,
      timeAgo: timeAgo(new Date()),
    };
  }
};

const removeHtml = (text: string) => {
  return text.replace(/<[^>]*>?/gm, '');
};

const orderBots = botId => {
  const index = allBots.value.findIndex(bot => bot.id === botId);
  const selectedBot = allBots.value.splice(index, 1)[0];
  allBots.value.unshift(selectedBot);
};

const changeAssistant = async botId => {
  if (botId === currentAssistant.value.botId) return;
  ready.value = false;
  cleanChunksVideo();
  orderBots(botId);
  disconnectFromBot();
  await connectToBot(botId);
  const savedAssistant = props.assistants.find(
    assistant => assistant.settings.botId === botId,
  );
  if (audioElement.value && audioOn.value) audioElement.value.muted = false;
  if (savedAssistant) {
    currentAssistant.value = savedAssistant.settings;
  }
};

onUnmounted(() => {
  disconnectFromBot();
});
</script>

<style scoped>
.assistant-trigger {
  @apply h-15 w-15 p-0.5;
  @apply border-1 rounded-full cursor-pointer z-20;
  @apply bg-white;
  @apply overflow-hidden;
  @apply absolute bottom-25 right-14 flex justify-center items-center;
}

.assistant-viewport {
  @apply absolute top-0 right-0 sm:top-3 sm:right-20 w-90 h-[calc(100vh-120px)] m-2 bg-slate-50 rounded-l-lg rounded-tr-lg overflow-hidden z-[999] shadow-dropdown-light;
}

.video-box {
  @apply flex justify-center w-full after:w-2/3 after:aspect-square after:opacity-0 border-b;
}

.text-input {
  @apply flex w-full border rounded-lg focus:outline-none focus:border-primary bg-secondary-75 h-10 px-4 transition-all;
}

.voice-input {
  @apply h-10 flex items-center justify-center gap-3 bg-primary hover:bg-primary-500 rounded-lg text-white px-2 flex-shrink-0;
}

.blur-edges {
  mask-image: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 20%),
    linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 20%);
  mask-composite: intersect;
}

.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

video {
  transition: opacity 0.2s;
  transition-timing-function: ease-in-out;
}

.settings {
  bottom: 0.8em;
  left: 1em;
}

.speed-btn {
  padding: 5px;
  width: 3em;
  border-radius: 4px;
  background: #ffffff33;
  font-size: 12px;
  font-weight: 600;
  color: #ffffff;
}
</style>
