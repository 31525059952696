<template>
  <div
    :dir="locale === 'en' ? 'ltr' : 'rtl'"
    class="preview-wrapper flex relative h-full overflow-hidden"
    :class="{ 'assistive-panel-open': showAssistivePanel }">
    <SimpleDrawer  v-if="!hideOutline" v-model:visible="showMenu" position="end" width="220px">
      <template #content>
        <div class="z-[9] pt-5 pb-10 h-full overflow-y-auto bg-secondary-75">
          <div
            class="panel-content overflow-y-auto h-full w-full border-s border-secondary-500">
            <LessonMenu
              :title="title"
              :outline="outline"
              :sections="sections"
              :current-outline="currentOutline"
              :current-section="currentSection"
              :last-section-index="lastSectionIndex"
              @click:outline="handleOutline"
              @click:navigation="enterSection"/>
          </div>
        </div>
      </template>

      <template #trigger>
        <div
          class="flex flex-col gap-3 items-center z-[1001] h-full absolute bg-transparent"
          v-if="!hideOutline" >
          <button
            class="absolute start-0 top-0 h-full bg-secondary-75 p-2 pt-3 flex justify-start"
            @click="showMenu = !showMenu">
            <div
              class="flex items-center justify-center bg-white rounded-md w-8 h-8">
              <ChevronRight v-if="showMenu" :class="{ 'scale-x-[-1]': !rtl }" />
              <Menu v-else />
            </div>
          </button>
        </div>
      </template>
    </SimpleDrawer>

    <div class="w-full relative">
      <FullScreenButton v-if="!hideOutline" />

      <PresentationDeck :id="id">
        <template #banner>
          <slot name="banner" />
        </template>
        <template #controls>
          <slot name="controls" />
        </template>
      </PresentationDeck>
    </div>

    <LeftDrawer v-model:show="showAssistivePanel" />
  </div>
</template>

<script setup lang="ts">
// @ts-nocheck
import PresentationDeck from './PresentationDeck.vue';
import { LessonMenu } from '@/components/index';
import { computed, provide, reactive, ref, watch } from 'vue';
import { useInteractions, useReveal } from '@/apps/slideshow/useReveal';
import { Menu, ChevronRight } from 'lucide-vue-next';
import LeftDrawer from '@/components/LeftDrawer.vue';
import { PROVIDE_TELEPORT_TARGETS } from './teleport/const';
import SimpleDrawer from '@amit/components/src/SimpleDrawer/SimpleDrawer.vue';
import FullScreenButton from '@/apps/slideshow/FullScreenButton.vue';

provide(PROVIDE_TELEPORT_TARGETS, reactive({}));
provide('inDeck', ref(true));

withDefaults(
  defineProps<{
    id: string;
    title: string;
    hideOutline: boolean;
  }>(),
  {
    id: '',
    title: '',
    hideOutline: false,
  },
);

const { onOutlineChange, onNavigationChange } = useInteractions();
const handleOutline = selected => onOutlineChange.trigger(selected);
const showMenu = ref(true);
const showAssistivePanel = ref(false);
const {
  outline,
  sections,
  lastSectionIndex,
  trackSlides,
  onSlide,
  toSlide,
  rtl,
  locale,
} = useReveal();
const currentSection = ref();
const currentOutline = computed(() => {
  let selected = outline.value.find(item => item.selected);
  let current = selected || outline.value[0];
  return current?.id;
});
defineEmits(['close-menu']);

watch(showAssistivePanel, newValue => {
  if (newValue) {
    showMenu.value = false;
  }
});

watch(showMenu, newValue => {
  if (newValue) {
    showAssistivePanel.value = false;
  }
});

onSlide(({ event }) => {
  let page = trackSlides.value.find(
    (page: any) => page.id === event.currentSlide.dataset.page,
  );

  currentSection.value = sections.value.find(
    lessonSection => lessonSection.id === page?.section,
  )?.id;
});

const isSectionAllowed = sectionIndex => {
  return sectionIndex <= lastSectionIndex.value;
};

const enterSection = (section, sectionIndex) => {
  if (!isSectionAllowed(sectionIndex)) return;
  onNavigationChange.trigger({});
  currentSection.value = section;
  toSlide(trackSlides.value.findIndex((page: any) => page.section === section));
};
</script>

<style>
.assistive-panel-open .slides {
  @apply !w-full mx-auto transition;
}
.assistive-panel-open .am-block {
  @apply pe-10;
}
.assistive-panel-open .drawer-panel-content .am-block {
  @apply pe-0;
}

.assistive-panel-open .slides > .slide {
  width: calc(100% - 60px) !important;
}
</style>
