<template>
  <SimpleDrawer v-model:visible="show" position="start">
    <template #content>
      <div
        class="panel-content drawer-panel-content overflow-y-auto h-full w-full border-s border-secondary-500">
        <PanelContent />
      </div>
    </template>

    <template #trigger>
      <div
        class="z-[9] flex flex-col gap-3 top-25 items-center h-fit absolute bg-transparent -start-[41px]"
        :class="{ 'scale-x-[-1]': !rtl }">
        <button
          v-for="(panel, index) in panels"
          :key="index"
          class="panel-toggle"
          :class="{
            'bg-white border-secondary-300 hover:bg-secondary-75':
              panelIndex !== index,
            'border-s-secondary-300 !border-e-secondary-500 ':
              panelIndex !== index && rtl,
            'border-s-secondary-500 !border-e-secondary-500 ':
              panelIndex !== index && !rtl,
            'border-secondary-500 bg-white': panelIndex === index,
            'border-s-secondary-500 !border-e-white':
              panelIndex === index && rtl,
            '!border-s-white': panelIndex === index && !rtl,
          }"
          @click="togglePanel(index)">
          <LucideIcon :name="panel.icon" :stroke-width="1" />
        </button>
      </div>
    </template>
  </SimpleDrawer>
</template>

<script setup lang="ts">
import { ref, h, computed } from 'vue';
import LucideIcon from '@/components/LucideIcon/LucideIcon.vue';
import { useArrayMap, useVModel } from '@vueuse/core';
import { useReveal } from '@/apps/slideshow/useReveal';
import { renderBlock } from '@/apps/slideshow/useBlockMapper';
import type { BuildingBlock } from '@/apps/slideshow/blocks';
import { SimpleDrawer } from '@amit/components';

const panelIndex = ref(-1);

const props = defineProps({
  show: {
    type: Boolean,
    default: false,
  },
});

const show = useVModel(props, 'show');

const { slideshow, rtl } = useReveal();

const panels = computed(() =>
  (slideshow.value?.panels ?? []).map(data => {
    return data;
  }),
);

const togglePanel = (index: number) => {
  if (panelIndex.value === index && show.value) {
    show.value = false;
    panelIndex.value = -1;
  } else {
    show.value = true;
    panelIndex.value = index;
  }
};

const PanelContent = () => {
  const blocks = useArrayMap(
    panels.value[panelIndex.value]?.blocks ?? [],
    (block: BuildingBlock) => {
      if (block.name === 'embed' && block?.settings?.proxy) {
        let proxyUri = `${import.meta.env.VITE_API_BASE}${
          block.settings.proxy
        }`;
        if (!block?.settings?.origin) {
          block.settings.origin = block.content.src;
        }
        block.content.src = `${proxyUri}?path=${encodeURI(
          block.settings.origin,
        )}&referer=${proxyUri}`;
      }

      return renderBlock({
        editable: false,
        block,
        display: 'pannel',
        state: [],
      });
    },
  );

  return h('div', { class: 'flex flex-col h-full' }, blocks.value);
};
</script>

<style>
.drawer-panel-content .am-block {
  @apply h-full;
}
.panel-toggle {
  @apply relative p-2 border-1 rounded-r-lg transition-all;
}
.drawer-panel-content .full-screen-button {
  @apply hidden;
}
</style>
