<template>
  <div
    class="flex relative"
    :class="{ 'flex-row-reverse': position === 'end' }">
    <aside
      :class="`h-screen transition-all duration-300 flex`"
      :style="{ width }">
      <div
        v-if="visible"
        class="flex-1 h-full relative transition-all duration-300">
        <slot name="content"></slot>
      </div>
    </aside>

    <slot name="trigger"></slot>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps({
  visible: {
    type: Boolean,
    default: false,
  },
  position: {
    type: String,
    default: 'start',
  },
  width: {
    type: String,
    default: '350px',
  },
});

// Emits event for toggling
const emit = defineEmits(['update:visible']);

const width = computed(() => (props.visible ? props.width : '0px'));
</script>
