<template>
  <button
    class="full-screen-button cursor-pointer absolute top-3 end-4 p-3 rounded-md bg-white z-[10] full-screen-shadow hover:scale-[1.1]"
    :title="fullScreenText"
    tabindex="0"
    @click="toggleFullScreen">
    <Minimize2 v-if="isFullScreen" :size="20" class="stroke-secondary-900" />
    <Maximize2 v-else :size="20" class="stroke-secondary-900" />
  </button>
</template>

<script setup lang="ts">
import { ref, onMounted, onUnmounted, computed, onUpdated } from 'vue';
import { Maximize2, Minimize2 } from 'lucide-vue-next';
import { useReveal } from '@/apps/slideshow/useReveal.ts';

const { getLocalizedText } = useReveal();

const isFullScreen = ref(false);

const elementsToHide = ['.space-header', '.app-header'];

const fullScreenText = computed(() =>
  isFullScreen.value
    ? getLocalizedText('exit_full_screen')
    : getLocalizedText('full_screen'),
);

const hideElements = hide => {
  elementsToHide.forEach(selector => {
    document.querySelectorAll(selector).forEach(el => {
      console.log('selector', selector);
      //@ts-ignore
      el.style.display = hide ? 'none' : '';
    });
  });
};

const toggleFullScreen = () => {
  if (!document.fullscreenElement) {
    document.documentElement
      .requestFullscreen()
      .then(() => {
        isFullScreen.value = true;
        hideElements(true);
      })
      .catch(err => console.error('Fullscreen error:', err));
  } else {
    document.exitFullscreen();
  }
};

const onFullScreenChange = () => {
  isFullScreen.value = !!document.fullscreenElement;
  hideElements(isFullScreen.value);
};

onUpdated(() => {
  onFullScreenChange();
});
onMounted(() => {
  onFullScreenChange();
  document.addEventListener('fullscreenchange', onFullScreenChange);
});

onUnmounted(() => {
  document.removeEventListener('fullscreenchange', onFullScreenChange);
});
</script>
