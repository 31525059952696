<template>
  <slot v-if="!hasReferrerPage" name="controls">
    <div
      class="flex items-center justify-between px-5 py-2 bg-white w-full absolute border-t border-blue-sky-200 bottom-0 left-auto right-0 gap-20"
      :style="{ 'z-index': 9 }">
      <TeleportTarget
        id="deck-info"
        class="h-10 md:mx-5 relative truncate transition-all"/>
      <div class="flex items-center justify-between gap-3">
        <button
          v-if="hasPrevSlide"
          class="px-2 py-1 rounded-lg bg-white border border-primary items-center justify-center z-[999]"
          @click="handlePrev">
          <ChevronRight class="stroke-primary w-4 ltr:rotate-180" />
        </button>
        <button
          class="relative flex rounded-lg px-4 h-9 items-center justify-center z-[999] transition-all w-max"
          :class="{
            'bg-secondary-700': !nextEnabled,
            'bg-primary': nextEnabled,
          }"
          :disabled="!nextEnabled || loading || (!hasNextSlide && isPreview)"
          @click="handleNext">
          <span class="text-white text-sm">
            {{
              hasNextSlide
                ? getLocalizedText('next')
                : getLocalizedText('finish')
            }}
          </span>
          <ChevronLeft
            v-if="hasNextSlide"
            class="stroke-white w-4 ltr:rotate-180"/>
          <LoadingOverlay
            v-model:active="loading"
            :can-cancel="false"
            :is-full-page="false"
            :height="20"/>
        </button>
      </div>
    </div>
  </slot>
</template>
<script setup lang="ts">
import { ChevronLeft, ChevronRight } from 'lucide-vue-next';
import LoadingOverlay from 'vue-loading-overlay';
import { useReveal } from '@/apps/slideshow/useReveal';
import { computed } from 'vue';
import TeleportTarget from './teleport/TeleportTarget.vue';
const {
  context,
  getLocalizedText,
  handlePrev,
  handleNext,
  hasPrevSlide,
  hasNextSlide,
  isPreview,
} = useReveal();

defineProps({
  hasReferrerPage: {
    type: Boolean,
    default: false,
  },
});
const nextEnabled = computed(() => context.value?.nextEnabled());
const loading = computed(() => context.value?.loading());
</script>
